<template>
  <div class="Help delete">
    <div class="header">
      <div class="header-text">
        <h2>{{ getString(Strings.The_Latest_To_Delete) }}</h2>
      </div>
    </div>
    <div class="Help-box">
      <div class="help-content">
        <div class="citile-showZi">
          {{ getString(Strings.Mind_Mindmap_Deleted_Tips) }}
        </div>
        <div class="hc-btn">
          <div class="delet-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              @click="searchButton()"
            />
            <input
              v-model="searchDeletText"
              @keyup.enter="searchButton()"
              class="search-box"
              type="text"
              :placeholder="getString(Strings.Mind_Mindmap_Deleted_Selected)"
            />
            <span
              v-if="searchDeletText != ''"
              @click="clearSearchText()"
              class="cleariconfont clear-search-text-icon"
              >&#xe605;</span
            >
          </div>
          <!-- 批量删除 -->
          <a-button
            v-if="isBatchRemove"
            class="batch-remove"
            type="primary"
            @click="deleteAll()"
          >
            {{ getString(Strings.Batch_Delete) }}
          </a-button>
          <!--<button class="batch-recovery" type="button">
            批量恢复
          </button> -->
        </div>
        <div v-if="isNowSearchDeletPage == false" class="hc-title">
          <div class="anniuncement" id="vueContainer">
            <div>
              <div class="ctitle ann-titles">
                <p class="filename">
                  <a-checkbox
                    v-if="isBatchRemove"
                    class="input-checkbox"
                    :checked="checkAll"
                    @click="checkedAll()"
                  ></a-checkbox>
                  {{ getString(Strings.Mind_Mindmap_Deleted_Filename) }}
                </p>
                <p class="nntime">
                  {{ getString(Strings.Mind_Mindmap_Deleted_Forever_Time) }}
                </p>
                <p class="operation">
                  {{ getString(Strings.Mind_Mindmap_Deleted_Operating) }}
                </p>
              </div>

              <div class="anilist">
                <div
                  class="ctitle listduo"
                  v-for="(item, index) in fruits"
                  :key="index"
                >
                  <div class="filename">
                    <a-checkbox
                      :checked="fruitIds.indexOf(item.id) > -1"
                      name="checkboxinput"
                      class="item-checkbox"
                      @click="checkedOne(item.id)"
                    ></a-checkbox>
                    <p class="fnimg">
                      <img
                        class="my-mind-img"
                        src="../../assets/img/user/Iitem0selected/defaultMindImg.png"
                        v-real-img="getMediaSrc(item.cover)"
                        alt=""
                      />
                    </p>
                    <div class="fnzi">
                      <p>{{ item.name }}</p>
                      <p>{{ item.content }}</p>
                    </div>
                  </div>
                  <div class="nntime oo">{{ item.createTime }}</div>
                  <div class="operation oo">
                    <!--默认删除按钮不能点击, 当选中某一checkbox时可以删除-->
                    <button @click="deletedRestore(item)">
                      <span class="iconfont2 iconfont2-slider">&#xe8f9;</span>
                      <span>{{
                        getString(Strings.Mind_Mindmap_Deleted_Recovery)
                      }}</span>
                    </button>
                    <button @click="deleteCompletely(item)">
                      <span class="iconfont2 iconfont2-slider">&#xe504;</span>
                      <span>{{
                        getString(Strings.Mind_Mindmap_Deleted_Forever)
                      }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="hc-title">
          <div class="anniuncement" id="vueContainer">
            <div>
              <div class="ctitle ann-titles">
                <p class="filename">
                  <a-checkbox
                    class="input-checkbox"
                    :checked="checkAll"
                    @click="checkedAll()"
                  ></a-checkbox>
                  {{ getString(Strings.Mind_Mindmap_Deleted_Filename) }}
                </p>
                <p class="nntime">
                  {{ getString(Strings.Mind_Mindmap_Deleted_Forever_Time) }}
                </p>
                <p class="operation">
                  {{ getString(Strings.Mind_Mindmap_Deleted_Operating) }}
                </p>
              </div>

              <div class="anilist">
                <div
                  class="ctitle listduo"
                  v-for="(item, index) in searchNewDeletData"
                  :key="index"
                >
                  <div class="filename">
                    <a-checkbox
                      :checked="fruitIds.indexOf(item.id) > -1"
                      name="checkboxinput"
                      class="item-checkbox"
                      @click="checkedOne(item.id)"
                    ></a-checkbox>
                    <p class="fnimg">
                      <img
                        class="my-mind-img"
                        src="../../assets/img/user/Iitem0selected/defaultMindImg.png"
                        v-real-img="getMediaSrc(item.cover)"
                        alt=""
                      />
                    </p>
                    <div class="fnzi">
                      <p>{{ item.name }}</p>
                      <p>{{ item.content }}</p>
                    </div>
                  </div>
                  <div class="nntime oo" :fileData="searchNewDeletData">
                    {{ item.createTime }}
                  </div>
                  <div class="operation oo">
                    <!--默认删除按钮不能点击, 当选中某一checkbox时可以删除-->
                    <button @click="deletedSearchRestore(item)">
                      <span class="iconfont2 iconfont2-slider">&#xe8f9;</span>
                      <span>{{
                        getString(Strings.Mind_Mindmap_Deleted_Recovery)
                      }}</span>
                    </button>
                    <button @click="deleteSearchCompletely(item)">
                      <span class="iconfont2 iconfont2-slider">&#xe504;</span>
                      <span>{{
                        getString(Strings.Mind_Mindmap_Deleted_Forever)
                      }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hc-btn"></div>
      </div>
      <div
        v-show="
          isNowSearchDeletPage == false &&
          fruits != '' &&
          fruits != null &&
          fruits.length != 0
        "
        class="delet-page-bottom"
      >
        <a-pagination
          class="pageChild"
          @change="onChangePage"
          :current="newPage"
          :total="totalPage * 10"
          show-less-items
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import {
  postMindmapListDeleted,
  postMindmapDeletedRestore,
  postMindmapDeleteCompletely,
  postMindmapSearchDeleted,
} from "../../common/netWork/mind_map_api";
import unifildStyle from "../../assets/css/help/unifildStyle.less";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import HttpServerConfig from "../../common/HttpServerConfig";
// import { formatDate } from '../../assets/js/util';

export default {
  inject: ["reload"],
  components: {},
  data() {
    return {
      fruits: [
        // {
        //   id:'',
        //   entityId:'',
        //   name:'',
        //   type:'',
        //   cover:'',
        //   content:'',
        //   modifyTime:'',
        //   createTime:''
        // },
      ], //最近删除的数据
      searchNewDeletData: [], //搜索的最近删除的数据
      fmidmap: {},
      fruitIds: [], //选中的导图数组
      isCheckedAll: false,
      searchDeletText: "",
      newPage: 1, //最近删除当前所在页面。
      totalPage: 0, // 总页数
      isNowSearchDeletPage: false, //是否在搜索页面。
      Strings,
      checkAll: false, //全选
      isBatchRemove: true, //是否显示批量删除
    };
  },
  created() {
  },
  mounted() {
    // this.listDeletedRequest()
    this.getNewDelet({ page: this.newPage }).then((res) => {
      let mindMaps = res.mindMaps
      this.fruits = mindMaps ? mindMaps : [];
      this.totalPage = res.totalPage;
      this.newPage = res.currentPage;
      this.isNowSearchDeletPage = false;
    });
  },
  //转换时间戳
  methods: {
    ...mapMutations(["reloadStorageSpace"]),
    getString(i) {
      return getString(i);
    },
    getMediaSrc(s) {
      return HttpServerConfig.getMediaSrc(s);
    },
    getNewDelet(obj) {
      //最近删除
      return new Promise((resolve, reject) => {
        postMindmapListDeleted(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
    getSearchNewDelet(obj) {
      //查寻最近删除
      return new Promise((resolve, reject) => {
        postMindmapSearchDeleted(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
    permanentDelet(obj) {
      //彻底删除导图
      return new Promise((resolve, reject) => {
        postMindmapDeleteCompletely(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
    recoverMindData(obj) {
      //恢复导图
      return new Promise((resolve, reject) => {
        postMindmapDeletedRestore(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    onChangePage(page) {
      this.getNewDelet({ page: page }).then((res) => {
        this.fruits = res.mindMaps;
        this.totalPage = res.totalPage;
        this.newPage = res.currentPage;
        this.isNowSearchDeletPage = false;
      });
    },

    searchButton() {
      //点击搜索按钮
      if (this.searchDeletText != "") {
        //走搜索
        // this.searchTemplate();
        this.getSearchNewDelet({ query: this.searchDeletText, page: 1 }).then(
          (res) => {
            let mindMap = res.mindMaps;
            if (mindMap && mindMap.length > 0) {
              this.searchNewDeletData = mindMap;
              this.isNowSearchDeletPage = true;
            } else {
              this.$message.warning(
                getString(strings.The_Search_Result_Is_Empty)
              );
            }
          }
        );
      } else {
        //走全部
        this.getNewDelet({ page: this.newPage }).then((res) => {
          let mindMap = res.mindMaps;
          if (mindMap && mindMap.length > 0) {
            this.fruits = mindMap;
          }
          this.totalPage = res.totalPage;
          this.newPage = res.currentPage;
          this.isNowSearchDeletPage = false;
        });
      }
      //清空全选
      this.checkAll = true;
      this.checkedAll();
    },

    clearSearchText() {
      this.searchDeletText = "";
      // this.templateHome();
    },

    //获取最近删除导图列表
    listDeletedRequest() {
      // let that = this
      // return new Promise(function(resolve, reject) {
      postMindmapListDeleted(
        { page: 0 },
        (res) => {
          this.fruits = res.mindMaps;
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
      // })
    },

    checkedOne(id) {
      let idIndex = this.fruitIds.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        // this.showZiDelete = false;
        this.fruitIds.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        // this.showZiDelete = true;
        this.fruitIds.push(id);
      }
    },

    //全选
    checkedAll() {
      this.checkAll = !this.checkAll;
      // 全选时
      if (this.checkAll) {
        this.fruitIds = [];
        let forEachData = null;
        if(this.isNowSearchDeletPage){
         forEachData = this.searchNewDeletData;
        } else{
         forEachData = this.fruits;
        }
        if (forEachData && forEachData.length > 0) {
            // 此写法 其实就是异步函数 this 的指向问题，传入一个this参数
            forEachData.forEach((val, index) => {
              this.fruitIds.push(val.id);
            });
            this.checkAll = true;
        }
      } else {
        this.fruitIds = [];
        this.checkAll = false;
      }
    },
    //批量删除
    deleteAll() {
      if (this.fruitIds && this.fruitIds.length == 0 ) {
        this.$message.error(
          "请选择删除的文件"
          // getString(strings.Message_Tips_Dlt_Success)
        );
        return
      }
      let that = this;
      this.$confirm({
        title: getString(Strings.Mind_Mindmap_Deleted_Forever),
        content: getString(Strings.Mind_Mindmap_Deleted_Tips),
        cancelText: getString(Strings.Global_Cancel),
        okText: getString(Strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          that.fruitIds.forEach((val, index) => {
            that
              .permanentDelet({ mindMapId: val })
              .then((res) => {
                return that.getNewDelet({ page: that.newPage });
              })
              .then((res) => {
                let len = that.fruitIds.length - 1;
                if (index == len) {
                  that.fruits = res.mindMaps;
                  that.totalPage = res.totalPage;
                  that.newPage = res.currentPage;
                  that.isNowSearchDeletPage = false;
                  that.fruitIds = [];
                  that.checkAll = false;
                  that.$message.success(
                    getString(strings.Message_Tips_Dlt_Success)
                  ); //删除成功
                  that.reloadStorageSpace(true);
                  that.clearSearchText();
                }
              });
          });
        },
        onCancel() {},
      });
    },
    //删除：彻底删除某个导图
    deleteCompletely(item) {
      let that = this;
      this.$confirm({
        title: getString(Strings.Mind_Mindmap_Deleted_Forever),
        content: getString(Strings.Mind_Mindmap_Deleted_Tips),
        cancelText: getString(Strings.Global_Cancel),
        okText: getString(Strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          that
            .permanentDelet({ mindMapId: item.id })
            .then((res) => {
              that.fruitIds = that.fruitIds.filter(sitem => sitem != item.id);
              return that.getNewDelet({ page: that.newPage });
            })
            .then((res) => {
              that.fruits = res.mindMaps;
              that.totalPage = res.totalPage;
              that.newPage = res.currentPage;
              that.isNowSearchDeletPage = false;
              that.$message.success(
                getString(strings.Message_Tips_Dlt_Success)
              ); //删除成功
              that.reloadStorageSpace(true);
              
            });
        },
        onCancel() {},
      });
    },

    //恢复已删除导图
    deletedRestore(item) {
      this.recoverMindData({ mindMapId: item.id, toParentId: "" })
        .then((res) => {
          return this.getNewDelet({ page: this.newPage });
        })
        .then((res) => {
          this.fruits = res.mindMaps;
          this.totalPage = res.totalPage;
          this.newPage = res.currentPage;
          this.isNowSearchDeletPage = false;
        });
    },

    deletedSearchRestore(item) {
      //恢复搜索的导图
      this.recoverMindData({ mindMapId: item.id, toParentId: "" })
        .then((res) => {
          // return this.getNewDelet({page: this.newPage})
          return this.getSearchNewDelet({
            query: this.searchDeletText,
            page: 1,
          });
        })
        .then((res) => {
          this.searchNewDeletData = res.mindMaps;
          this.isNowSearchDeletPage = true;
        });
    },

    deleteSearchCompletely(item) {
      //删除搜索的导图
      let that = this;
      this.$confirm({
        title: getString(Strings.Mind_Mindmap_Deleted_Forever),
        content: getString(Strings.Mind_Mindmap_Deleted_Tips),
        cancelText: getString(Strings.Global_Cancel),
        okText: getString(Strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          that
            .permanentDelet({ mindMapId: item.id })
            .then((res) => {
              return that.getSearchNewDelet({
                query: that.searchDeletText,
                page: 1,
              });
            })
            .then((res) => {
              that.searchNewDeletData = res.mindMaps;
              that.isNowSearchDeletPage = true;
              that.reloadStorageSpace(true);
            });
        },
        onCancel() {},
      });
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
  watch: {
    fruits(newVal, oldVal) {
      //在监听属性里面好像不用担心数组修改过后视图没有响应的问题。
      if (newVal != null) {
        let len = newVal.length;
        for (let i = 0; i < len; i++) {
          let date = new Date(newVal[i].createTime * 1000);
          newVal[i].createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
        }
        if (newVal.length > 0) {
          this.isBatchRemove = true;
        }else{
          this.isBatchRemove = false;
        }
      }else{
        this.isBatchRemove = false;
      }
    },
    fruitIds(newVal){
      if (newVal && newVal.length > 0) {
        this.checkAll = false;
        if(this.isNowSearchDeletPage){
          if (newVal.length == this.searchNewDeletData.length ) {
            this.checkAll = true;
          }
        } else {
          if (newVal.length == this.fruits.length ) {
            this.checkAll = true;
          }
        }
        
      }
    },
    searchNewDeletData(newVal, oldVal) {
      //在监听属性里面好像不用担心数组修改过后视图没有响应的问题。
      if (newVal != null) {
        let len = newVal.length;
        for (let i = 0; i < len; i++) {
          let date = new Date(newVal[i].createTime * 1000);
          newVal[i].createTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
        }
      }
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.header-text h2 {
  left: 0px;
  font-size: 20px;
  font-family: Noto Sans SC;
  color: #333333;
  font-weight: bold;
  opacity: 1;
}
.delete {
  padding-left: 30px;
}

.hc-btn {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
// .hc-btn button{
//   width: 80px;
//   height: 40px;
//   margin-right: 1em;
//   font-size: 14px;
//   font-family: Noto Sans SC;
//   font-weight: 400;
//   line-height: 14px;
//   float: right;
//   opacity: 1;
//   border-radius: 4px;

// }
// .hc-btn button:focus{
// 	outline: none;
// }

.batch-recovery {
  border: none;
  color: #666;
  background: #e8e8e8;
}
.batch-recovery:hover {
  background: #d5d5d5;
}
.batch-remove {
  padding: 0 14px;
  border: none;
  color: #fff;
  background: #fd492b;
  border-radius: 2px;
  cursor: pointer;
}
// .batch-remove:hover{
//   background: #ff7354;
// }
.hc-title .ann-titles {
  height: 54px;
  background: #f0f2f8;
  opacity: 1;
  font-size: 14px;
  font-family: Noto Sans SC;
  /*font-weight: bold;*/
  color: #333333;
}
.hc-title .ctitle {
  display: flex;
  width: 100%;
}
p {
  margin-bottom: unset;
}
.hc-title .ann-titles p {
  margin-bottom: unset;
  line-height: 3.8em;
}
.hc-title .ctitle .filename input {
  margin-right: 2em;
}
.hc-title .filename {
  width: 68%;
  padding-left: 20px;
}
.hc-title .nntime {
  width: 17%;
  text-align: center;
}
.hc-title .operation {
  width: 15%;
  text-align: center;
}
.citile-showZi {
  position: absolute;
  font-size: 12px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #fd492b;
  padding-left: 20px;
  line-height: 28px;
}
.anilist {
  margin-top: 16px;
}
.anilist .operation button {
  line-height: normal;
}
.anilist .listduo {
  height: 70px;
}
.anilist .listduo .filename {
  display: flex;
}
.anilist .listduo .filename input {
  margin-top: 2em;
}
.anilist .listduo .filename .fnimg {
  padding-top: 1em;
  margin-right: 10px;
}
.anilist .listduo .filename .fnimg img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
}
.anilist .listduo .filename .fnzi {
  padding-top: 1.2em;
}
.anilist .listduo .oo {
  margin-top: 2em;
}
.anilist .listduo .oo button {
  border: none;
  background: none;
  padding: 0px 12px;
  cursor: pointer;
}
.anilist .listduo .oo button:hover {
  color: #fd492b;
}
.anilist .listduo .oo:focus {
  outline: none;
}
.anilist .listduo .oo button:focus {
  outline: none;
}
.anilist .listduo .filename input:focus {
  outline: none;
}

@font-face {
  font-family: "iconfont2";
  src: url("../../assets/font-icon/font_6ea7c34k864/iconfont.eot");
  src: url("../../assets/font-icon/font_6ea7c34k864/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_6ea7c34k864/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_6ea7c34k864/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_6ea7c34k864/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_6ea7c34k864/iconfont.svg#iconfont")
      format("svg");
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  /*font-weight: bold;*/
  font-style: normal;
  /* color: #000; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
}

@font-face {
  font-family: "cleariconfont";
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
  src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
      format("svg");
}

.cleariconfont {
  font-family: "cleariconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.delet-search {
  float: right;
  width: 300px;
  // height: 50px;
  padding-right: 20px;
  // display: flex;
  // flex-direction: row-reverse;
  // align-items: center;
  position: relative;
  .clear-search-text-icon {
    display: block;
    position: absolute;
    top: 0;
    // transform: translate(0,-50%);
    font-size: 18px;
    left: 250px;
    height: 36px;
    line-height: 36px;
    color: #bbb;
    cursor: pointer;
    &:hover {
      color: #ff7354;
    }
    &:active {
      color: #d6301a;
    }
  }

  .search-button {
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    border: 0px;
    background: none;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background: #fd492b;
    color: #fff;
    opacity: 1;
    border: none;
  }
  .search-box {
    width: 100%;
    border-radius: 16px;
    border: transparent;
    background: #f0f2f8;
    margin-right: 10px;
    height: 36px;
    padding-left: 45px;
  }
  .search-box:focus {
    outline: none;
  }
}

.delet-page-bottom {
  position: relative;
  margin-top: 30px;
  margin-bottom: 50px;
  .pageChild {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.input-checkbox {
  margin-right: 20px;
}
.item-checkbox {
  margin-right: 20px;
  margin-top: 24px;
}
</style>
