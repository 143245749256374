import {apiRequest} from './base'

//上次更新的导图列表checksum
export function postMindmapChecksum(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/checksum',req,onOk,onFail,eventually,headers,onProgress)
}
//拉取所有云端文件信息
export function postMindmapPull(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/pull',req,onOk,onFail,eventually,headers,onProgress)
}
//列出文件夹下所有文件
export function postMindmapListDir(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/list-dir',req,onOk,onFail,eventually,headers,onProgress)
}
//列出最近编辑的导图列表
export function postMindmapListEntities(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/list-entities',req,onOk,onFail,eventually,headers,onProgress)
}
//获取文件夹信息
export function postMindmapGetEntity(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/get-entity',req,onOk,onFail,eventually,headers,onProgress)
}
//获取导图内容
export function postMindmapGetMindmap(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/get-mindmap',req,onOk,onFail,eventually,headers,onProgress)
}
//获取AI内容
export function postMindmapGetAi(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/ask-chatgpt',req,onOk,onFail,eventually,headers,onProgress)
}
//新建导图
export function postMindmapNew(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/new',req,onOk,onFail,eventually,headers,onProgress)
}
//新建导图是否提示导图数量超出限制
export function postMindmapGetNumExceed(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/get-num-exceed',req,onOk,onFail,eventually,headers,onProgress)
}
//按类型新建导图
export function postMindmapNewByType(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/new-by-type',req,onOk,onFail,eventually,headers,onProgress)
}
//复制导图
export function postMindmapCopy(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/copy',req,onOk,onFail,eventually,headers,onProgress)
}
//新建目录
export function postMindmapNewDir(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/new-dir',req,onOk,onFail,eventually,headers,onProgress)
}
//删除文件
export function postMindmapDelete(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete',req,onOk,onFail,eventually,headers,onProgress)
}
//搜索导图
export function postMindmapQuery(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/query',req,onOk,onFail,eventually,headers,onProgress)
}
//移动导图或文件夹
export function postMindmapMove(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/move',req,onOk,onFail,eventually,headers,onProgress)
}
//修改文件夹
export function postMindmapUploadDir(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-dir',req,onOk,onFail,eventually,headers,onProgress)
}
//重命名
export function postMindmapAlias(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/alias',req,onOk,onFail,eventually,headers,onProgress)
}
//上传导图
export function postMindmapUploadMindmap(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-mindmap',req,onOk,onFail,eventually,headers,onProgress)
}
//上传图片
export function postMindmapUploadImage(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-image',req,onOk,onFail,eventually,headers,onProgress)
}
//删除图片
export function postMindmapDeleteImage(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-image',req,onOk,onFail,eventually,headers,onProgress)
}
//获取最近删除的导图列表
export function postMindmapListDeleted(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/list-deleted',req,onOk,onFail,eventually,headers,onProgress)
}
//搜索最近删除的导图
export function postMindmapSearchDeleted(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/search-deleted',req,onOk,onFail,eventually,headers,onProgress)
}
//恢复已删除的导图
export function postMindmapDeletedRestore(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/deleted-restore',req,onOk,onFail,eventually,headers,onProgress)
}
//彻底删除某个导图
export function postMindmapDeleteCompletely(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-completely',req,onOk,onFail,eventually,headers,onProgress)
}
//获取导图基本信息
export function postMindmapCheckMindmap(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/check-mindmap',req,onOk,onFail,eventually,headers,onProgress)
}
//播放导图
export function postMindmapPlay(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/play',req,onOk,onFail,eventually,headers,onProgress)
}
//上传附件
export function postMindmapUploadAttachment(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-attachment',req,onOk,onFail,eventually,headers,onProgress)
}
//删除附件
export function postMindmapDeleteAttachments(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-attachments',req,onOk,onFail,eventually,headers,onProgress)
}
//导入.xjmind文件
export function postMindmapImportXjmind(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/import-xjmind',req,onOk,onFail,eventually,headers,onProgress)
}
//ocr扫描生成导图
export function postMindmapOcrScan(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/ocr-scan',req,onOk,onFail,eventually,headers,onProgress)
}
//查询剩余ocr扫描次数
export function postMindmapOcrInfo(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/ocr-info',req,onOk,onFail,eventually,headers,onProgress)
}
//分享导图
export function postMindmapShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/share',req,onOk,onFail,eventually,headers,onProgress)
}
//修改分享状态
export function postMindmapModifyShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/modify-share',req,onOk,onFail,eventually,headers,onProgress)
}
//重新生成分享链接
export function postMindmapReshare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/reshare',req,onOk,onFail,eventually,headers,onProgress)
}
//删除分享
export function postMindmapDeleteShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-share',req,onOk,onFail,eventually,headers,onProgress)
}
//收藏分享链接
export function postMindmapCollectShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/collect-share',req,onOk,onFail,eventually,headers,onProgress)
}
//取消收藏分享链接
export function postMindmapUncollectShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/uncollect-share',req,onOk,onFail,eventually,headers,onProgress)
}
//保存分享链接到我的导图
export function postMindmapSaveShare(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/save-share',req,onOk,onFail,eventually,headers,onProgress)
}
//获取当前账号可录音最大时长
export function postMindmapAudioLimit(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/audio-limit',req,onOk,onFail,eventually,headers,onProgress)
}
//上传音频-需要登录
export function postMindmapUploadAudio(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-audio',req,onOk,onFail,eventually,headers,onProgress)
}
//删除音频
export function postMindmapDeleteAudio(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-audio',req,onOk,onFail,eventually,headers,onProgress)
}
//查看分享链接
export function postMindmapView(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/view',req,onOk,onFail,eventually,headers,onProgress)
}
//访问关联导图
export function postMindmapViewConnectmap(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/view-connectmap',req,onOk,onFail,eventually,headers,onProgress)
}
//新建分页
export function postMindmapNewSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/new-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//删除分页
export function postMindmapDeleteSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//调整分页顺序
export function postMindmapMoveSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/move-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//分页重命名
export function postMindmapModifySheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/modify-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//复制分页
export function postMindmapCopySheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/copy-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//拉取分页列表
export function postMindmapListSheets(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/list-sheets',req,onOk,onFail,eventually,headers,onProgress)
}
//搜索分页
export function postMindmapSearchSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/search-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//迁移分页到另一张导图
export function postMindmapMigrateSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/migrate-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//导出分页为一张新的导图
export function postMindmapExportSheet(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/export-sheet',req,onOk,onFail,eventually,headers,onProgress)
}
//拉取归档列表
export function postMindmapQueryArchives(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/query-archives',req,onOk,onFail,eventually,headers,onProgress)
}
//导入归档
export function postMindmapArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/archive',req,onOk,onFail,eventually,headers,onProgress)
}
//取消归档
export function postMindmapUnarchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/unarchive',req,onOk,onFail,eventually,headers,onProgress)
}
//复制归档导图
export function postMindmapCopyArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/copy-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//删除归档
export function postMindmapDeleteArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//移动归档
export function postMindmapMoveArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/move-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//修改归档
export function postMindmapModifyArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/modify-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//移动归档顺序
export function postMindmapRearrangeArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/rearrange-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//新建归档文件夹
export function postMindmapNewArchiveDir(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/new-archive-dir',req,onOk,onFail,eventually,headers,onProgress)
}
//搜索归档
export function postMindmapSearchArchive(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/search-archive',req,onOk,onFail,eventually,headers,onProgress)
}
//拉取备份列表
export function postMindmapListBackups(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/list-backups',req,onOk,onFail,eventually,headers,onProgress)
}
//查看备份内容
export function postMindmapViewBackup(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/view-backup',req,onOk,onFail,eventually,headers,onProgress)
}
//备份还原
export function postMindmapRestoreBackup(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/restore-backup',req,onOk,onFail,eventually,headers,onProgress)
}
//备份删除
export function postMindmapDeleteBackup(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/delete-backup',req,onOk,onFail,eventually,headers,onProgress)
}
//我的LaTex模版
export function postMindmapMyLatex(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/my-latex',req,onOk,onFail,eventually,headers,onProgress)
}
//上传我的LaTex模板
export function postMindmapUploadMyLatex(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/mindmap/upload-my-latex',req,onOk,onFail,eventually,headers,onProgress)
}
